import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contacts() {
    const formRef = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_kh3z7lh', 'template_q91j19e', formRef.current, 'hWtqgtEeABeoIlr1A')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        e.target.reset();
      };
    return(
    <section id="contact" className="background-section" style={{backgroundImage:`url(${require('../img/contacts.jpg')})`}}>
                <div className='background-gradient'></div>
                <div className="section-element-wrapper">
                    <div className="contact-wrapper">
                        <div className="contact-title-wrapper">
                            <h4 className="contact-title">Contact</h4>
                            <span style={{display:'none'}} className='contact-number'>Number: +38(000)-000-0000</span>
                        </div>
                        <form ref={formRef} onSubmit={sendEmail} className="contact-inputs-wrapper">
                            <input type="text" required name="user_name" placeholder="First Name"/>
                            <input type='tel' required name='user_number' placeholder="Telephone"/>
                            <input name="user_email" type="email" required placeholder="Email"/>
                            <textarea name="message" className="contact-textarea" placeholder="Your Message" rows={4} cols={2} dataname="message"></textarea>
                            <button type='submit' className='contact-button'>Submit Form</button>
                        </form>
                    </div>
                </div>
            </section>
    )
}