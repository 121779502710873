import { Twirl as Hamburger } from 'hamburger-react'
import { useState } from 'react';
import scrollToElement from '../utils/scrollToElement';
import { AnimatePresence, motion } from 'framer-motion';


export default function Header({width, isOpen, setIsOpen}) {
        return (
            <header className="Header_wrapper">
                {width > 750 ? 
                (
                <div className='navigation-wrapper'>
                    <div className='navigation-button' onClick={()=>{scrollToElement('home')}} >Home</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('about')}} >About</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('training')}} >Training</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('service')}} >Service</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('contact')}} >Contact</div>
                </div>
                ):(
                    <Hamburger toggled={isOpen} size={32} toggle={setIsOpen} />
                )}
            </header>
        )
}