import './App.css';
import './Adaptation.css';
import MainPage from './pages/Main';
import './fonts/Montserrat/fonts.css';

function App() {
  return (
    <MainPage/>
  );
}

export default App;
