import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Contacts from "../components/Contacts";
import { AnimatePresence, motion } from "framer-motion";
import scrollToElement from "../utils/scrollToElement";

export default function MainPage() {
    const [width, setWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(()=>{
        window.addEventListener("resize", (e)=>{setWidth(window.innerWidth);});
    },[])
    return (
        <div className="App">
            <Header isOpen={isOpen} setIsOpen={setIsOpen} width={width}/>
            <AnimatePresence>
                {isOpen && (
                <motion.div
                initial={{ transform:'translateY(-100%)' }}
                animate={{ transform: 'translateY(0)' }}
                exit={{ transform: 'translateY(-100%)' }}
                className="burger-menu">
                    <div className='navigation-button' onClick={()=>{scrollToElement('home'); setIsOpen(false)}} >Home</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('about'); setIsOpen(false)}} >About</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('training'); setIsOpen(false)}} >Training</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('service'); setIsOpen(false)}} >Service</div>
                    <div className='navigation-button' onClick={()=>{scrollToElement('contact'); setIsOpen(false)}} >Contact</div>
                </motion.div>
                 )}
                 </AnimatePresence>
            {isOpen && <div style={{position:'fixed', height:'100%', width:'100%', zIndex:2}} onClick={()=>{setIsOpen(false)}}></div>}
            <section className="background-section main-background-section" id="home">
                <div className="background-gradient"></div>
                <div className="section-element-wrapper">
                </div>
            </section>
            <section className="section-black" id="about">
                <motion.div 
                whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                viewport={{ once: true, amount: 0.8 }}
                transition={{
                    duration: 0.4,
                    delay: 0.1
                }} 
                className="point-wrapper">
                    <motion.div whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.1
                    }} 
                className="point-title-wrapper">
                        <div className="point-line"></div>
                        <div className="point-title">About</div>
                    </motion.div>
                    <div className="point-text">
                        <p>Alita Security is was founded sens 2017. Founders of this company have extensive experience in the maritime security industry since 2011.</p>
                        <p>During its existence, the company has trained more than 1000 qualified MSO who have completed a special anti-piracy course at the company's training center from among the former military and police. Our operatives have successfully worked in the Top PMSCs in the global maritime security industry around the world.</p>
                        <p>Our company team consists of highly qualified employees with extensive experience and strictly adheres to industry and international standards such as ISO 28007, ISO 9001 etc.</p>
                    </div>
                </motion.div>
                <motion.div
                whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                    duration: 0.4,
                    delay: 0.1
                }} 
                className="image-wrapper">
                    {width > 1050 ? (<img src={require('../img/photo_2023-07-13_13-38-47.jpg')} width='100%' />) : (<img src={require('../img/photo_2023-07-13_13-38-47.jpg')} width='100%'/>)}
                </motion.div>
            </section>
            <section className="section-black" id="training">
                <motion.div
                whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                viewport={{ once: true, amount: 0.8 }}
                transition={{
                    duration: 0.4,
                    delay: 0.1
                }} 
                className="point-wrapper">
                    <div className="point-title-wrapper">
                        <div className="point-line"></div>
                        <div className="point-title">Training</div>
                    </div>
                    <div className="point-text">
                        <p>The company has a training center in Odessa Ukraine for conducting MSO courses.</p>
                        <p>All company cources conduct qualified instructors with many years of experience.</p>
                        <p>The company conducts trainings and issues certificates such as MSO,TMC,MFCC.</p>
                    </div>
                </motion.div>
                <div className="video-wrapper">
                    <motion.div
                    whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.1
                    }} 
                    className="video">
                        {width > 820 ? (
                            <video width='100%' disableRemotePlayback disablePictureInPicture autoPlay loop muted>
                                <source src={require('../img/Training.mp4')} type="video/mp4"/>
                            </video>
                        ):(
                            <video width='100%' disableRemotePlayback disablePictureInPicture autoPlay loop muted>
                                <source src={require('../img/Training.mp4')} type="video/mp4"/>
                            </video>
                        )
                        }
                        
                    </motion.div>
                    <motion.div
                    whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.3
                    }} 
                    className="video">
                        {width > 820 ? (
                            <video width='100%' disableRemotePlayback disablePictureInPicture autoPlay loop muted>
                                <source src={require('../img/Training2.mp4')} type="video/mp4"/>
                            </video>
                        ):(
                            <video width='100%' disableRemotePlayback disablePictureInPicture autoPlay loop muted>
                                <source src={require('../img/Training2.mp4')} type="video/mp4"/>
                            </video>
                        )
                        }
                    </motion.div>
                </div>
            </section>
            <section className="section-black" id="service">
                <motion.div
                whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                viewport={{ once: true, amount: 0.8 }}
                transition={{
                    duration: 0.4,
                    delay: 0.1
                }} 
                className="point-wrapper">
                    <div className="point-title-wrapper">
                        <div className="point-line"></div>
                        <div className="point-title">Service</div>
                    </div>
                    <div className="point-text">
                        <p>The company provides a global human resource that meets all certifications in the maritime security industry. All of our employees are highly experienced in the military, police and maritime security and are ready to deploy anywhere in the world.</p>
                    </div>
                </motion.div>
                <motion.div
                whileInView={{ opacity: 1 }} initial={{opacity:0}} 
                viewport={{ once: true, amount: 0.4 }}
                transition={{
                    duration: 0.4,
                    delay: 0.1
                }} 
                className="image-wrapper">
                    {width > 780 ? (<img src={require('../img/service.jpg')} width='100%'/>) : (<img src={require('../img/service.jpg')} width='100%'/>)}
                    
                </motion.div>
            </section>
            <Contacts />
        </div>
    );
}
